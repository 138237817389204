import { ActivityTypePatchDTO } from 'models/ActivityTypePatch'
import { RankingElementPatchDTO } from 'models/RankingPatch'
import { TotalDataDTO } from 'models/TotalData'

export const benefitsMock: string[] = [
  'co2',
  'absenteeism',
  'traffic',
  'ptt',
  'sustanaible',
]

export const actionsMock: string[] = [
  'incentives',
  'carpooling',
  'mobility',
  'remote',
  'transport',
  'parking',
  'electricCar',
  'bus',
  'communicate',
]

export const totalDataMock: TotalDataDTO = {
  kms: 998222,
  co2: 123523,
  money_saving: 187957,
  ciclos_total: 1230073,
  active_users: 460,
  total_activities: 118237,
  handled_users: 658,
  new_users: 658,
}

export const rankingMock: RankingElementPatchDTO[] = [
  {
    uuid: '2e21a1f6-fa44-463b-95ea-e78d4f1ef270',
    name: 'FAGOR EDERLAN',
    distance: 9850,
    co2: 1022,
    ciclos: 10260,
    total_activities: 1008,
    active_users: 46,
  },
  {
    uuid: '145335a8-811f-4301-836c-a149d46b02ec',
    name: 'FAGOR ARRASATE',
    distance: 5879,
    co2: 770,
    ciclos: 9619,
    total_activities: 698,
    active_users: 28,
  },
  {
    uuid: '1cb0555c-f645-4b02-8eb7-f911df53cf89',
    name: 'FAGOR AUTOMATION',
    distance: 4318,
    co2: 579,
    ciclos: 7077,
    total_activities: 735,
    active_users: 33,
  },
  {
    uuid: '7465c3c9-6d73-4384-8e3b-1d5e8f069918',
    name: 'FAGOR INDUSTRIAL',
    distance: 5053,
    co2: 586,
    ciclos: 5081,
    total_activities: 483,
    active_users: 25,
  },
  {
    uuid: 'f92294d6-6294-4543-9b72-778ec8b871f4',
    name: 'COPRECI',
    distance: 3193,
    co2: 319,
    ciclos: 2726,
    total_activities: 278,
    active_users: 8,
  },
  {
    uuid: '39af45ad-d144-4dfa-958c-cdb367146fa4',
    name: 'FAGOR ELEKTRONIKA',
    distance: 2339,
    co2: 182,
    ciclos: 1676,
    total_activities: 170,
    active_users: 9,
  },
  {
    uuid: '6961b2f2-eeac-4867-941f-5c9704fbd5d8',
    name: 'MONDRAGON ASSEMBLY',
    distance: 385,
    co2: 58,
    ciclos: 957,
    total_activities: 75,
    active_users: 6,
  },
  {
    uuid: '9bd81421-7f74-4afd-b26b-2efc61166840',
    name: 'FAGOR S. COOP',
    distance: 255,
    co2: 20,
    ciclos: 65,
    total_activities: 26,
    active_users: 2,
  },
  {
    uuid: '507fed78-88d1-4d74-8cc4-aaffd838d69f',
    name: 'GALBAIAN',
    distance: 0,
    co2: 0,
    ciclos: 0,
    total_activities: 0,
    active_users: 0,
  },
]

export const activitiesStatsMock: ActivityTypePatchDTO[] = [
  {
    kms: 167522,
    co2: 12396,
    money_saving: 32819,
    ciclos: 93042,
    active_users: 78,
    total_activities: 6752,
    activity_type: 'Bus Ride',
    activity_type_uuid: '3259878f-19b6-4ced-9319-002685312bf3',
  },
  {
    kms: 352424,
    co2: 60264,
    money_saving: 64629,
    ciclos: 706086,
    active_users: 269,
    total_activities: 58099,
    activity_type: 'Cycling',
    activity_type_uuid: '792a54b7-abc0-4364-851a-3553258300dd',
  },
  {
    kms: 38812,
    co2: 6559,
    money_saving: 7204,
    ciclos: 39005,
    active_users: 61,
    total_activities: 5233,
    activity_type: 'Electric Bike',
    activity_type_uuid: '494bf222-27a8-4417-b3a1-7dffda1a3bd9',
  },
  {
    kms: 6229,
    co2: 1052,
    money_saving: 1085,
    ciclos: 6318,
    active_users: 27,
    total_activities: 1720,
    activity_type: 'Electric Scooter',
    activity_type_uuid: '0a7c506e-ceb5-4768-8657-93d984990979',
  },
  {
    kms: 1641,
    co2: 280,
    money_saving: 300,
    ciclos: 3529,
    active_users: 32,
    total_activities: 259,
    activity_type: 'Running',
    activity_type_uuid: '8287ad69-976d-4432-b24d-945993c938c8',
  },
  {
    kms: 358020,
    co2: 30431,
    money_saving: 70651,
    ciclos: 89699,
    active_users: 111,
    total_activities: 9932,
    activity_type: 'Share Ride',
    activity_type_uuid: 'd960d630-7034-49e4-98e6-cde8e643bdc5',
  },
  {
    kms: 530,
    co2: 90,
    money_saving: 94,
    ciclos: 1602,
    active_users: 15,
    total_activities: 117,
    activity_type: 'Skating',
    activity_type_uuid: '9bcf8976-4df3-443b-b2d1-b655db75c972',
  },
  {
    kms: 109,
    co2: 15,
    money_saving: 20,
    ciclos: 96,
    active_users: 5,
    total_activities: 17,
    activity_type: 'Subway Ride',
    activity_type_uuid: '04c9ab3d-a713-4e73-ae75-50fbdd4a1791',
  },
  {
    kms: 1550,
    co2: 224,
    money_saving: 297,
    ciclos: 1278,
    active_users: 16,
    total_activities: 138,
    activity_type: 'Train Ride',
    activity_type_uuid: '94ad1cc6-6fe9-4195-b88f-94bf9d4f5b07',
  },
  {
    kms: 71381,
    co2: 12206,
    money_saving: 10854,
    ciclos: 289418,
    active_users: 236,
    total_activities: 35970,
    activity_type: 'Walking',
    activity_type_uuid: 'e09d610a-d165-4dd8-ba1b-ecf3d9937917',
  },
]
