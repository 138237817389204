import { ViewportProvider } from 'context/viewportProvider'
import { PublicPrivateInterceptor } from 'interceptors'
import Navigation from 'routes/Navigation'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tooltip/dist/react-tooltip.css'

PublicPrivateInterceptor()

function App() {
  return (
    <ViewportProvider>
      <Navigation />
    </ViewportProvider>
  )
}

export default App
